@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

body {
    margin: 0;
    padding: 0;
}

.four-cards-card {
    position: relative;
    display: inline-block;
    width: 45%;

}

.four-cards-container {
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    width: 60%;
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
}


.four-cards-card {
    position: relative;
    display: inline-block;
    width: 55%;
}


.four-cards-card img {
    display: block;
}

.four-cards-card h2 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: white;
    padding: 5px 10px;
    width: 60%;
    z-index: 1;

    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
}

.four-cards {
    display: flex;
    justify-content: center;
    align-items: center;

}

.four-cards-card {
    flex: 1;
    margin: 0 10px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    position: relative;

}

.four-cards-card img {

    width: 100%;
    height: 600px;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
}

.four-cards-card h3 {
    margin: 10px;
    font-size: 1.4rem;
    font-weight: 600;
    color: #333;
    text-align: center;
    text-transform: uppercase;
}

.four-cards-card p {
    margin: 10px;
    font-size: 1.1rem;
    color: #777;
    text-align: center;
    line-height: 1.5;
    max-width: 80%;
    margin: 0 auto;
}

.four-cards-card::before {

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.6) 100%);
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.four-cards-card:hover::before {
    opacity: 1;
}

.four-cards-card:hover h3,
.four-cards-card:hover p {
    color: #fff;
}

.four-cards-card:hover button {
    opacity: 1;
    transform: translateY(0);
}

.four-cards-card button {
    position: absolute;
    bottom: 10px;
    right: 10px;
    padding: 10px 20px;
    background-color: #fff;
    color: #333;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 20px;
    border: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    opacity: 0;
    transform: translateY(10px);
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
}


@media only screen and (max-width: 768px) {
    .four-cards-container {
        display: block;
        width: 100%;
        text-align: center;
        margin-top: 20px;
        overflow-x: scroll;
        white-space: nowrap;
        padding-right: 40px; /* Add some space on the right side */
        scroll-snap-type: x mandatory; /* Snap horizontally to the nearest card */
    }

    .four-cards-container::-webkit-scrollbar {
        display: none; /* Hide the scrollbar */
    }

    .four-cards-card {
        width: 90%;
        height: 30em;
        margin: 20px;
        display: inline-block;
        scroll-snap-align: start; /* Snap each card to the start */
    }
}

