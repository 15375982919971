.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    background-color: #5b1789;
    /*box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);*/
    padding: 0 40px;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
}

.navbar-logo {
    height: 50px;
}

.navbar-list {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
}

.navbar-item {

    margin: 0 20px;
    cursor: pointer;
    color: white;
    transition: color 0.2s ease-in-out;

}

.navbar-item:hover {
    color: #00ef6f;
}

@media only screen and (max-width: 768px) {
    .navbar {
        flex-direction: column;
        justify-content: center;
        padding: 20px;
        height: auto;
    }

    .navbar-left {
        margin-bottom: 20px;
    }

    .navbar-item {
        margin: 10px 10px;
    }


}
