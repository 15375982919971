
*{
    animation-duration: 4s;


}
.upload-form {
width: 100%;
    align-items: flex-start;
    padding: 32px;

}

.input-file {
    float: left;
    display: none;
    transform: skew(-20deg) !important;

}

.file-label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px;
    background-color: #8f00d3;
    color: #fff;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-bottom: 32px;
}

.file-label:hover {
    background-color: rgb(123, 5, 180);

}

.file-label span {
    margin-right: 8px;
    font-weight: bold;

}

.submit-btn{
    float: left;

}

.upload-new{
    transform: skew(-0deg) !important;
    height: 3.61rem;
    width: 100%;

}

.dropdown {
    width: 100%;


}
.upload-form-dropdown select{
    width: 100%;
}

.dropdown select {
    padding: 8px 16px;
    font-size: 16px;
    border: none;
    background-color: #2e2d2e;

    color: white;
    font-size: 20px;

    height: 100%;
    margin-bottom: 2em;


}

.dropdown select:focus {
    outline: none;
}

.dropdown input {
    padding: 8px 16px;
    font-size: 26px;
    border: none;
    border-left: 1px solid #ccc;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
}

.dropdown input:focus {
    outline: none;
}


