body{
    background-color: #1d1920;
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    /*font-family: Tomorrow;*/
    background-image: url("https://www.vrcafehaarlem.nl/wp-content/uploads/2021/05/Group-208.png");
    /*font-family: "Rubik"*/
    /*font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;*/
}

a{
    text-decoration: none;
}


/*button{*/
/*    font-family: "Tomorrow";*/
/*    font-weight: 800;*/
/*    text-transform: uppercase;*/
/*}*/
