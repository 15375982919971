
.navbar-brand {
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.navbar-nav {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.navbar-nav li {
    margin: 0 10px;
}

.navbar-nav li a {
    color: #fff;
    text-decoration: none;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: all 0.3s ease;
}

.navbar-nav li a:hover {
    color: gray;
}

.checkbox-container {
    display: flex;
    align-items: center;
}

input[type="checkbox"] {
    margin-right: 5px;

}

label {
    margin-right: 40px;
    color: white;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 10px;
}


