.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 20px;
    margin: 30px;
}

.grid-item {
    background-color: #2a272d;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    overflow: hidden;
    transition: transform 0.2s ease-in-out;

}

.grid-item:hover {
    transform: scale(1.02);
}

.grid-item-folder {
    background-color: #2a272d;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    overflow: hidden;
    transition: transform 0.2s ease-in-out;
    height: 40px;
    width: 100%;
}

.grid-item-folder:hover {
    transform: scale(1.02);
}

.item-header {

    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #343138;
}

.item-header-left {
    width: 90%;
    overflow: hidden;
}

.item-header-left h3 {
    white-space: nowrap;
    text-overflow: ellipsis;
}

.item-header-right {
    width: 10%;

}

.item-header-right img {
    float: right;
}

.item-header i {
    font-size: 20px;
    margin-right: 10px;
}

.item-header h3 {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;
}

.item-preview img,
.item-preview video {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.item-preview img {
    display: block;
}

.item-preview video {
    display: flex;
    justify-content: center;
    align-items: center;
}

.item-preview video::-webkit-media-controls {
    display: none !important;
}

.item-icon {
    height: 20px;
}


.upload-container.upload-visible {
    opacity: 1;
    transform: translateY(0);
}

.navbar-item-filter {

    color: white;
    text-decoration: none;
    text-underline: none;
    float: left;
    height: 100%;
    margin-top: 1em;
    margin-left: 20px;
    list-style: none;

}

.navbar-item-filter:hover {
    color: #00ef6f;
    font-weight: bold;

}

.navbar-item-filter-button {
    margin-left: 60px;

}

.upload-indicator-container {
    float: left;
    margin-left: 20px;
}

.left-side-menu {
    height: 100%;
    width: 20%;
    float: left;

}

.left-side-menu h3 {

}

.grid-container {
    flex: 1;

    text-overflow: ellipsis;
}

.right-side-menu {
    height: 100%;
    width: 80%;
    float: left;
}

.item-header-left-upload {

    text-align: center;
}


.item-header-upload {

    width: 100%;
    align-items: center;
    padding: 10px;
    background-color: #343138;
}

.item-header-upload i {


}

.item-header-upload h3 {

    font-size: 18px;
    font-weight: 500;
    color: #ffffff;
}

@media only screen and (max-width: 1508px) {
    .left-side-menu {

        width: 100%;

    }
    .right-side-menu {
        width: 100%;
    }
}

@media only screen and (max-width: 768px) {
    .left-side-menu {
        width: 100%;

    }

    .right-side-menu {
        width: 100%;
    }

    .upload-indicator {
        text-align: center;
    }

    .upload-indicator-container {
        text-align: center;
        margin-top: 3.5em;
        float: none;
    }
}
